html {
    font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: FrutigerNext;
    src: local(FrutigerNext), url('/public/fonts/Frutiger_Next.ttf') format('truetype');
}

@font-face {
    font-family: FrutigerNext-Bd;
    src: local(FrutigerNext-Bd), url('/public/fonts/Frutiger_Next_Bd.ttf') format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}
iframe {
    right: 4px !important;
    left: auto !important;
    bottom: auto !important;
    top: 16px !important;
}

iframe:nth-child(2) {
    bottom: auto !important;
    top: -18px !important;
    transition: all 0.5s ease;


}
/*use a css transition to move the iframe down */

iframe:nth-child(2):hover {
    transition: all 0.5s ease;
    bottom: auto !important;
    top: 16px !important;
}


.tooltipText {
    font-family: FrutigerNext;
    font-size: 1.4rem;
    color: #fff;
    margin-bottom: 0.5rem;
}



