body.export {
    margin: 0;
    padding: 0;
    background-color: #cccccc;
    font-family: Verdana;
    box-sizing: border-box;
    font-size: 16px !important;

}

body.export #container {
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
    max-width:960px;
    min-height:100px;
    position: relative;
    background-color: #ffffff;
    -webkit-box-shadow: -1px 3px 13px 2px rgba(0,0,0,0.41);
    box-shadow: -1px 3px 13px 2px rgba(0,0,0,0.41);
}

body.export #header {
    background-color: #ffcb00;
    background: linear-gradient(0deg, rgba(255,203,0,1) 0%, rgba(255,229,131,1) 56%, rgba(255,229,131,1) 77%, rgba(255,209,30,1) 100%);
    font-size:32px;
    text-align: center;
    padding:20px;
    font-weight:700;
    color:#ffffff;

}

body.export .subheader {
    background-color: #ffcb00;
    background: linear-gradient(0deg, rgba(255,203,0,1) 0%, rgba(255,229,131,1) 56%, rgba(255,229,131,1) 77%, rgba(255,209,30,1) 100%);
    font-size:22px;
    text-align: center;
    padding:10px;
    font-weight:700;
    color:#ffffff;
}

body.export .subheader>div, #header>div {
    color: transparent;
    background: #999999;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 1px 1px 1px rgb(255 255 255);
}

body.export #content {
    background-color: #ffffff;
}

body.export .col {
    float:left;
    width:50%;
}



body.export img {
    max-width: 100%;
    height: auto;
    display:block;
}

body.export .elevationElement img {
    width:160px;
    height: auto;
    float: left;
    margin-right: 20px;
}

body.export a.elevationElement {
    display: block;
    background-color: #efefef;
    width: 100%;
    float: none;
    clear: both;
    margin-bottom: 10px;
    color: #000000;
    text-decoration: none;
    padding: 10px;
}

body.export .spacer {
    width:100%;
    float:none;
    clear:both;
}

body.export .text {
    padding:20px;
}
/*
[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  -khtml-user-drag: element;
  -webkit-user-drag: element;
}*/

.export-table {
    border-collapse: collapse;
    border: 1px solid #dddddd;
    text-align: right;
}

.export-table td {
    border-collapse: collapse;
    border: 1px solid #dddddd;
}
body.export td {
    padding:2px 5px;
}

body.export .infoblockTitle {
    background-color: #ffcb00;
    /*background: linear-gradient(0deg, rgba(255,229,131,1) 20%, rgba(255,229,131,1) 92%, rgba(255,209,30,1) 100%);*/
}

body.export .tcontent {
    text-align: right;
    padding: 3px 10px;
}

body.export .tlabel {
    text-align: left;
    padding: 3px 10px;
}

body.export #copied {
    display: none;
    background-color: white;
    padding: 28px;
    position: absolute;
    left: calc(50vw - 100px);
    top: 100px;
    width: 200px;
    text-align: center;
    -webkit-box-shadow: 2px 5px 16px 5px rgba(0,0,0,0.51);
    box-shadow: 2px 5px 16px 5px rgba(0,0,0,0.51);
    pointer-events: none;
}

body.export .left {
    float:left;
}

body.export .alignLeft{
    text-align: left;
}

body.export .bold {
    font-weight: bold;
}

body.export .boldLeft {
    font-weight: bold;
    text-align: left;
}

body.export .alignLeft {
    text-align: left;
}

body.export #cost_table {
    width:100%;
}