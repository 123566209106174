
.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
    background: #ffffff;


}

.css-264g9t-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    font-size: 1.4rem !important;
}

.css-1m0vmat-MuiButtonBase-root-MuiPickersDay-root {
    font-size: 1.4rem !important;
}

.css-1m0vmat-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #F4F6F7 !important;
}

.css-1q04gal-MuiDateCalendar-root {
    max-height: 30rem !important;
}

