@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.loadingSpinner {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    width: 40px;
    height: 40px;
    animation: rotation 2s infinite linear;
}